export default (obj) => {
  const objCopy = { ...obj };

  // remove null values
  /* eslint-disable */
  for (let key in objCopy) {
    if (
      objCopy[key] === null ||
      objCopy[key] === undefined ||
      objCopy[key].length === 0
    ) {
      delete objCopy[key];
    }
  }

  /* eslint-enable */
  return objCopy;
};
