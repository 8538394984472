import React from 'react';
import { Input, Button, Form } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import ARSpinner from '../ARSpinner/ARSpinner';
import './LoginForm.scss';

const FormItem = Form.Item;

const LoginForm = (props) => {
  const {
    hasApiError,
    authLoading,
    handleInputFocus,
    apiErrorMessage,
    handleSubmit,
  } = props;

  return (
    <Form onFinish={handleSubmit} className="login-form">
      <FormItem
        name="email"
        rules={[
          { required: true, message: 'Please input your Email Address!' },
        ]}
      >
        <Input
          onFocus={handleInputFocus}
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email Address"
        />
      </FormItem>
      <FormItem
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          onFocus={handleInputFocus}
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="Password"
        />
      </FormItem>
      <FormItem>
        {hasApiError === true && (
          <span className="error-message">{apiErrorMessage}</span>
        )}

        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </FormItem>
      {authLoading && <ARSpinner full />}
    </Form>
  );
};

export default LoginForm;
