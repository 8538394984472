import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './ARSpinner.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ARSpinner = ({ full }) => {
  const classNames = ['ar-spinner'];

  if (full) classNames.push('ar-spinner--full');

  return <Spin indicator={antIcon} className={classNames.join(' ')} />;
};

export default ARSpinner;
