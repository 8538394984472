import jwt_decode from 'jwt-decode';
import { setAuth } from '../ducks/auth';
import { refreshAuthToken } from './zorro/auth';
import store from '../store';

const setAuthToStore = (auth) => store.dispatch(setAuth(auth));

const timeTillTokenExpires = (token) => {
  const tokenExpiry = jwt_decode(token).exp;
  const dateNow = Math.floor(Date.now() / 1000);

  return (tokenExpiry - dateNow) * 1000;
};

const refreshTokenTimer = (auth, refreshToken) => {
  const tokenRefreshTime = timeTillTokenExpires(auth.token);
  const tokenRefreshTimeBuffer = 120000;

  setTimeout(() => {
    refreshToken(auth);
  }, Math.max(tokenRefreshTime - tokenRefreshTimeBuffer, 0));
};

const refreshToken = async (auth) => {
  try {
    const refreshedToken = await refreshAuthToken();
    const refreshedAuth = {
      ...auth,
      token: refreshedToken.data.token,
    };

    setAuthToStore(refreshedAuth);
    refreshTokenTimer(refreshedAuth, refreshToken);
  } catch (error) {
    if (error.response?.status >= 500) {
      setTimeout(() => refreshToken(), 30000);
    }
  }
};

export const initRefreshToken = (auth) => refreshTokenTimer(auth, refreshToken);

export default initRefreshToken;
