import convertToStartCase from './convertToStartCase';

export default (text) => {
  const breadcrumbIsUuid = text.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  );

  if (breadcrumbIsUuid) return text;

  return convertToStartCase(text);
};
