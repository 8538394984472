import React, { useEffect, useState } from 'react';
import './styles/global-styles.scss';
import { useDispatch } from 'react-redux';
import Routes from './router/routes';
import { setAuth } from './ducks/auth';
import { setBrands, setCurrentBrand } from './ducks/brands';
import { initRefreshToken } from './services/tokenRefresh';

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('v1_token');
    const brands = localStorage.getItem('v1_brands');
    const currentBrand = localStorage.getItem('v1_current-brand');

    if (token && brands) {
      dispatch(setAuth({ token }));
      dispatch(setBrands(JSON.parse(brands)));
      if (currentBrand) dispatch(setCurrentBrand(currentBrand));
      initRefreshToken({ token });
    }

    setLoading(false);
  }, [dispatch]);

  if (loading) return null;

  return <Routes />;
};

export default App;
