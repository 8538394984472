import axios from 'axios';
import { message } from 'antd';
import userLogout from '../ducks/logout';
import { setCurrentBrand } from '../ducks/brands';
import store from '../store';

const endpoint = process.env.REACT_APP_API_URL;

const getHeaders = () => ({
  Authorization: `Bearer ${store.getState().auth?.token}`,
});

axios.defaults.headers.post['Content-Type'] = 'application/json';

// Add a response interceptor
if (window.location.pathname.indexOf('/login') === -1) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // The request was made and the server responded with a status code
      if (error.response) {
        // 400 Bad Request - The request was unacceptable, usually due to a missing or malformed parameter.
        if (error.response.status === 400) {
          message.error(`Bad request.`);
        }

        // 401 Unauthorized -	The API token was incorrect or missing.
        if (error.response.status === 401) {
          store.dispatch(userLogout());
        }

        // 403 Forbidden - The user doesn’t have permission to make this request.
        if (error.response.status === 403) {
          message.error(`You don't have access to this page.`);
        }

        // 429 Too Many Requests - Too many requests have been made in a given time period. This only applies to unauthenticated requests.
        if (error.response.status === 429) {
          message.error('Too many requests. Please try again later.');
        }

        // 404 Not Found - The resource requested doesn’t exist.
        if (error.response.status === 404) {
          message.error('Page not found.');
        }

        // 405 Method Not Allowed
        if (error.response.status === 405) {
          message.error('Method Not Allowed.');
        }

        // 500 Internal Server Error	Something went wrong on the Zorro API side.
        if (error.response.status >= 500 && error.response.status < 600) {
          message.error('Woops, Something went wrong, please try again later.');
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        message.error('Network error, please try again later.');
      } else {
        // Something happened in setting up the request that triggered an Error
        message.error('An unknown error occured, please try again later.');
      }

      return Promise.reject(error);
    }
  );
}

const getBrand = () => {
  const currentState = store.getState();
  const { currentBrand } = currentState.brands;
  const defaultBrand = currentState.brands.brands[0];
  const localCurrentBrand = localStorage.getItem('v1_current-brand');

  if (!currentBrand) {
    store.dispatch(setCurrentBrand(localCurrentBrand || defaultBrand));

    return localCurrentBrand || defaultBrand;
  }

  return currentBrand;
};

export const get = async (url, config) =>
  axios.get(`${endpoint}/partner/brand/${getBrand()}${url}`, {
    headers: getHeaders(),
    ...config,
  });

export const getNoBrand = (url, config) =>
  axios.get(`${endpoint}${url}`, {
    headers: getHeaders(),
    ...config,
  });

export const post = (url, data, config) =>
  axios.post(`${endpoint}/partner/brand/${getBrand()}${url}`, data, {
    headers: getHeaders(),
    ...config,
  });

export const postNoBrand = (url, args) =>
  axios.post(`${endpoint}${url}`, args, {
    headers: getHeaders(),
  });

export const postNoAuth = (url, args) => axios.post(`${endpoint}${url}`, args);

export const patchNoBrand = (url, args) =>
  axios.patch(`${endpoint}${url}`, args, {
    headers: getHeaders(),
  });

export const deleteNoBrand = (url) =>
  axios.delete(`${endpoint}${url}`, {
    headers: getHeaders(),
  });

export const put = (url, data, config) =>
  axios.put(`${endpoint}/partner/brand/${getBrand()}${url}`, data, {
    headers: getHeaders(),
    ...config,
  });

export const putNoBrand = (url, args) =>
  axios.put(`${endpoint}${url}`, args, {
    headers: getHeaders(),
  });

export const patch = (url, data, config) =>
  axios.patch(`${endpoint}/partner/brand/${getBrand()}${url}`, data, {
    headers: getHeaders(),
    ...config,
  });
