import convertToISODuration from './convertISODuration';
import convertDateToMicrosecondTimestamp from './convertDateToMicrosecondTimestamp';

export default (criteria) =>
  criteria
    .map((criterion) => {
      switch (criterion.criterion_type) {
        case 'member_brand':
          return {
            criterion_type: 'member_brand',
            __version: 1,
            brand_id: criterion.value,
          };
        case 'day_of_the_week':
          return {
            criterion_type: 'day_of_the_week',
            __version: 1,
            days: criterion.value,
          };
        case 'member_retail_group_index':
          return {
            criterion_type: 'member_retail_group_index',
            __version: 1,
            retail_group_id: criterion.value.retailGroupID,
            index_start: criterion.value.start,
            index_end: criterion.value.end,
          };

        case 'member_not_transacted':
          if (
            criterion.criterion_subtype ===
            'member_not_transacted_at_retail_group_recently'
          ) {
            return {
              criterion_type: 'member_not_transacted_at_retail_group_recently',
              __version: 1,
              retail_group_id: criterion.value.retailGroupID,
              transacted_within: convertToISODuration(
                criterion.value.unit,
                criterion.value.amount
              ),
            };
          }

          if (
            criterion.criterion_subtype === 'member_not_transacted_recently'
          ) {
            return {
              criterion_type: 'member_not_transacted_recently',
              __version: 1,
              not_transacted_within: convertToISODuration(
                criterion.value.unit,
                criterion.value.amount
              ),
            };
          }

          return null;
        case 'member_transacted_at_retail_group_recently':
          return {
            criterion_type: 'member_transacted_at_retail_group_recently',
            __version: 1,
            retail_group_id: criterion.value.retailGroupID,
            transacted_within: convertToISODuration(
              criterion.value.unit,
              criterion.value.amount
            ),
          };
        case 'minimum_spend_criterion':
          return {
            criterion_type: 'minimum_spend_criterion',
            __version: 1,
            threshold: {
              amount: criterion.value.amount * 100,
              currency: criterion.value.currency,
            },
          };
        case 'period_of_time':
          return {
            criterion_type: 'period_of_time',
            __version: 1,
            start_date: convertDateToMicrosecondTimestamp(criterion.value[0]),
            end_date: convertDateToMicrosecondTimestamp(criterion.value[1]),
          };
        case 'member_offer_visibility_period':
          return {
            criterion_type: 'member_offer_visibility_period',
            __version: 1,
            start_date: convertDateToMicrosecondTimestamp(criterion.value[0]),
            end_date: convertDateToMicrosecondTimestamp(criterion.value[1]),
          };
        case 'time_of_day':
          return {
            criterion_type: 'time_of_day',
            __version: 1,
            start_time: criterion.value.startTime,
            end_time: criterion.value.endTime,
          };
        case 'member_minimum_boost_level':
          return {
            criterion_type: 'member_minimum_boost_level',
            __version: 1,
            minimum_boost_level: criterion.value.minBoostLevel,
          };
        case 'member_maximum_boost_level':
          return {
            criterion_type: 'member_maximum_boost_level',
            __version: 1,
            maximum_boost_level: criterion.value.maxBoostLevel,
          };

        case 'member_registered':
          if (criterion.criterion_subtype === 'member_registration_date') {
            return {
              criterion_type: 'member_registration_date',
              __version: 1,
              start_date: convertDateToMicrosecondTimestamp(
                criterion.value.betweenDates[0]
              ),
              end_date: convertDateToMicrosecondTimestamp(
                criterion.value.betweenDates[1]
              ),
            };
          }

          if (criterion.criterion_subtype === 'member_registered_recently') {
            return {
              criterion_type: 'member_registered_recently',
              __version: 1,
              registered_within: convertToISODuration(
                criterion.value.unit,
                criterion.value.amount
              ),
            };
          }

          return null;
        case 'channel':
          return {
            criterion_type: 'channel',
            __version: 1,
            channel: criterion.value,
          };
        case 'member_network':
          return {
            criterion_type: 'member_network',
            __version: 1,
            network_id: criterion.value,
          };
        case 'member_retail_group_average_spend_value':
          return {
            criterion_type: 'member_retail_group_average_spend_value',
            __version: 1,
            retail_group_id: criterion.value.retailGroupID,
            min_spend: {
              amount: criterion.value.minSpendAmount * 100,
              currency: criterion.value.spendAmountCurrency,
            },
            max_spend: {
              amount: criterion.value.maxSpendAmount * 100,
              currency: criterion.value.spendAmountCurrency,
            },
            time_window: convertToISODuration(
              criterion.value.timeUnit,
              criterion.value.timeValue
            ),
          };
        default:
          return null;
      }
    })
    .filter((item) => item !== null);
