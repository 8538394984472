import { combineReducers } from 'redux';
import auth from './auth';
import brands from './brands';
import retailGroups from './retailGroups';

const appReducer = combineReducers({
  auth,
  brands,
  retailGroups,
});

const rootReducer = (state, action) =>
  appReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);

export default rootReducer;
