import capitalize from './capitalize';

export default (firstName, lastName) => {
  if (!firstName && !lastName) return null;

  if (firstName && lastName) {
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  }

  return firstName ? capitalize(firstName) : capitalize(lastName);
};
