/**
 * Encodes a string to base64.
 *
 * @param {string} - A string to be encoded to base64
 * @return {string} - A base64 encoded string
 */
export default (string) => {
  if (typeof string !== 'string') {
    throw new TypeError('Parameter is not a string');
  }

  return window.btoa(string);
};
