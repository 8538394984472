import parseISODuration from './parseISODuration';
import convertMicrosecondTimestampToDate from './convertMicrosecondTimestampToDate';

export default (criteria) =>
  criteria.map((criterion) => {
    switch (criterion.criterion_type) {
      case 'member_brand':
        return {
          criterion_type: 'member_brand',
          value: criterion.brand_id,
        };
      case 'day_of_the_week':
        return {
          criterion_type: 'day_of_the_week',
          value: criterion.days,
        };
      case 'member_retail_group_index':
        return {
          criterion_type: 'member_retail_group_index',
          value: {
            retailGroupID: criterion.retail_group_id,
            start: criterion.index_start,
            end: criterion.index_end,
          },
        };
      case 'member_not_transacted_at_retail_group_recently':
        return {
          criterion_type: 'member_not_transacted',
          criterion_subtype: 'member_not_transacted_at_retail_group_recently',
          value: {
            retailGroupID: criterion.retail_group_id,
            unit: parseISODuration(criterion.transacted_within)[0],
            amount: parseISODuration(criterion.transacted_within)[1],
          },
        };
      case 'member_not_transacted_recently':
        return {
          criterion_type: 'member_not_transacted',
          criterion_subtype: 'member_not_transacted_recently',
          value: {
            unit: parseISODuration(criterion.not_transacted_within)[0],
            amount: parseISODuration(criterion.not_transacted_within)[1],
          },
        };
      case 'member_transacted_at_retail_group_recently':
        return {
          criterion_type: 'member_transacted_at_retail_group_recently',
          value: {
            retailGroupID: criterion.retail_group_id,
            unit: parseISODuration(criterion.transacted_within)[0],
            amount: parseISODuration(criterion.transacted_within)[1],
          },
        };
      case 'period_of_time':
        return {
          criterion_type: 'period_of_time',
          value: [criterion.start_date, criterion.end_date].map((date) =>
            convertMicrosecondTimestampToDate(date)
          ),
        };
      case 'member_offer_visibility_period':
        return {
          criterion_type: 'member_offer_visibility_period',
          value: [criterion.start_date, criterion.end_date].map((date) =>
            convertMicrosecondTimestampToDate(date)
          ),
        };
      case 'minimum_spend_criterion':
        return {
          criterion_type: 'minimum_spend_criterion',
          value: {
            amount: criterion.threshold.amount / 100,
            currency: criterion.threshold.currency,
          },
        };
      case 'time_of_day':
        return {
          criterion_type: 'time_of_day',
          value: {
            startTime: criterion.start_time,
            endTime: criterion.end_time,
          },
        };
      case 'member_minimum_boost_level':
        return {
          criterion_type: 'member_minimum_boost_level',
          value: {
            minBoostLevel: criterion.minimum_boost_level,
          },
        };
      case 'member_maximum_boost_level':
        return {
          criterion_type: 'member_maximum_boost_level',
          value: {
            maxBoostLevel: criterion.maximum_boost_level,
          },
        };
      case 'member_registered_recently':
        return {
          criterion_type: 'member_registered',
          criterion_subtype: 'member_registered_recently',
          value: {
            unit: parseISODuration(criterion.registered_within)[0],
            amount: parseISODuration(criterion.registered_within)[1],
          },
        };
      case 'channel':
        return {
          criterion_type: 'channel',
          value: criterion.channel,
        };
      case 'member_network':
        return {
          criterion_type: 'member_network',
          value: criterion.network_id,
        };
      case 'member_retail_group_average_spend_value':
        return {
          criterion_type: 'member_retail_group_average_spend_value',
          value: {
            maxSpendAmount: criterion.max_spend.amount / 100,
            minSpendAmount: criterion.min_spend.amount / 100,
            spendAmountCurrency: criterion.max_spend.currency,
            retailGroupID: criterion.retail_group_id,
            timeUnit: parseISODuration(criterion.time_window)[0],
            timeValue: parseISODuration(criterion.time_window)[1],
          },
        };
      case 'member_registration_date':
        return {
          criterion_type: 'member_registered',
          criterion_subtype: 'member_registration_date',
          value: {
            betweenDates: [
              convertMicrosecondTimestampToDate(criterion.start_date),
              convertMicrosecondTimestampToDate(criterion.end_date),
            ],
          },
        };
      default:
        return null;
    }
  });
