import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import ARSpinner from '../components/ARSpinner/ARSpinner';
import Login from '../pages/Login';
import { base64Decode, base64Encode } from '../utilities';

const Dashboard = lazy(() => import('../pages/Dashboard'));

const Routes = () => {
  const { currentBrand } = useSelector((state) => state.brands);
  const { token } = useSelector((state) => state.auth);

  const getNextRedirect = (props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const decodedNextQuery = searchParams.get('next')
      ? base64Decode(searchParams.get('next'))
      : '';
    const pathname = decodedNextQuery.match(/^([^?]*)(\?.*)?/)[1];
    const query = decodedNextQuery.match(/^([^?]*)(\?.*)?/)[2];

    return decodedNextQuery ? (
      <Redirect
        to={{
          pathname,
          search: query,
        }}
      />
    ) : (
      <Redirect to={`/dashboard/${currentBrand}`} />
    );
  };

  return (
    <Router>
      <Suspense fallback={<ARSpinner full />}>
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => (token ? getNextRedirect(props) : <Login />)}
          />
          <Route
            path="/dashboard/:brand"
            render={(props) =>
              token ? (
                <Dashboard />
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                    search: `?next=${base64Encode(
                      props.location.pathname + props.location.search
                    )}`,
                  }}
                />
              )
            }
          />
          <Route
            path="/"
            render={(props) =>
              token ? getNextRedirect(props) : <Redirect to="/login" />
            }
          />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
