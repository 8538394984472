/**
 * Decodes a base64 encoded string.
 *
 * @param {string} - A base64 string to be decoded
 * @return {string} - A decoded string
 */
export default (encodedString) => {
  if (typeof encodedString !== 'string') {
    throw new TypeError('Parameter is not a string');
  }

  return window.atob(encodedString);
};
